import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import PageBanner from '../components/PageBanner'
import SEO from '../components/SEO'
import { Container, PagePadding, defaultTheme } from '../styles'
import styled from 'styled-components'

const ReviewsPage = () => {
    const data = useStaticQuery(graphql`
        {
            allTestimonialsJson {
                nodes {
                    author
                    reviewBody
                }
            }
        }
    `)
    return (
        <Layout>
            <SEO
                title=""
                metaDesc=""
            />
            <PageBanner title="Client Reviews" subtitle="Los Angeles Personal Injury Lawyer" />
            <Container>
                <PagePadding>
                    {data.allTestimonialsJson.nodes.map(testimonial => {
                        return <Review key={testimonial.reviewBody}>
                                <div dangerouslySetInnerHTML={{ __html: testimonial.reviewBody }} />
                                <h4>{testimonial.author}</h4>
                            </Review>
                    })}
                </PagePadding>
            </Container>
        </Layout>
    )
}

export default ReviewsPage

const Review = styled.div`
    margin: 0 0 32px;
    padding: 24px 32px;
    /* border: 1px solid ${defaultTheme.darkBlue}; */
    border: 5px solid;
    border-image-source: radial-gradient(circle, ${defaultTheme.lightBlue} 0%, ${defaultTheme.darkBlue} 100%);
    border-image-slice: 1;


     p {
         font-size: 18px;
         font-weight: 300;
         line-height: 1.618;
     }
`;

